import { useHints } from "@/lib/client-hints";
import { useRequestInfo } from "@/lib/request-info";
import { parseWithZod } from "@conform-to/zod";
import { useFetchers } from "@remix-run/react";
import { z } from "zod";

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
	const hints = useHints();
	const requestInfo = useRequestInfo();
	const optimisticMode = useOptimisticThemeMode();
	if (optimisticMode) {
		return optimisticMode === "system" ? hints.theme : optimisticMode;
	}
	return requestInfo.userPrefs.theme ?? hints.theme;
}

export const ThemeFormSchema = z.object({
	theme: z.enum(["system", "light", "dark"]),
});

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
	const fetchers = useFetchers();
	const themeFetcher = fetchers.find(
		(f) => f.formAction === "/app?/changeTheme",
	);

	if (themeFetcher?.formData) {
		const submission = parseWithZod(themeFetcher.formData, {
			schema: ThemeFormSchema,
		});
		if (submission.status !== "success") return;
		return submission.value?.theme;
	}
}
